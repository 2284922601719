interface TextInputProps {
  name: string;
  type: string;
  value: string;
  disabled?: boolean;
  onChange: (val: string) => void;
  displayFormat?: (val: number) => string;
}



export default function TextInput(props: TextInputProps) {
  return (
    <input
      type={props.type}
      placeholder= "Jouw email"
      className={
        'h-[36px] w-full border-[1px] p-1 pl-2 text-sm text-[#303030] transition-color duration-500 ' +
        (props.disabled ? 'border-[#cccccc] bg-slate-200' : 'border-black')
      }
      onChange={(e) => props.onChange(e.target.value)}
      disabled={props.disabled}
      
    />
  );
}
