import { useState } from "react";
import Turnaround from './icons/turnaround.png';
import Filter from './icons/filter.png';
import Floor from './icons/floor.png';

export default function Instructions()
{
    const [showInstructions, setShowInstructions] = useState(true);
    return <div className={"fixed w-full h-full top-0 left-0 flex text-sm z-[1000]"}>
        <div className={(showInstructions ? "w-full h-full flex flex-col text-center mt-auto ml-auto mr-auto transition-opacity duration-1500" : "hidden")}>
            <div className={"w-full h-full mx-auto align-middle bg-black/80"}>
                <div className={"grid m-auto w-4/6 grid-cols-3 text-white gap-4 justify-center my-80 h-36 align-middle"}>
                    <div className={"justify-items-center text-center"}>
                        <img src={Turnaround} className={"p-5 w-24 h-24 mx-auto"}></img>
                        Gebruik je muis om rond het gebouw te draaien.</div>
                    <div className={"justify-items-center text-center"}>
                        <img src={Filter} className={"p-5 w-24 h-24 mx-auto"}></img>
                        Gebruik de filters aan de rechterkant en verklein het aantal units.</div>
                    <div className={"justify-items-center text-center"}>
                        <img src={Floor} className={"p-5 w-24 h-24 mx-auto"}></img>
                        Klik op een vloer om naar het grondplan te gaan.</div>
                    <div className={"justify-items-center text-center"}></div>
                    <div className={"justify-items-center text-center"}><button className="rounded-full bg-white w-1/2 h-10 text-black font-bold mt-8 hover:bg-zinc-200 hover:border-1 hover:border-black" onClick={(e) => { e.preventDefault(); setShowInstructions(!showInstructions); }}>Start</button></div>
                </div>  
            </div>
        </div>
    </div> 
}