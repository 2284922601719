interface PButtonProps {
  title: string;
  disabled?: boolean;
  onClick?: () => void;
}

export default function PButton(props: PButtonProps) {
  return (
    <button
      onClick={() => (props.onClick ? props.onClick() : null)}
      className={
        'select-none cursor-pointer h-[42px] text-white hover:text-slate-200 flex font-bold w-full transition-color duration-500 ' +
        (props.disabled ? 'bg-[#cccccc]' : 'bg-black')
      }
      disabled={props.disabled}
    >
      <span className="m-auto">{props.title}</span>
    </button>
  );
}
