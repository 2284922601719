
export default function Loading(props: { progress: number }) {
    const percentage: number = Math.floor(props.progress * 100);

    return <div className="m-auto flex flex-col justify-around align-bottom rounded w-[445px] h-[80px] bg-white text-black">
        <div className="m-auto flex flex-col align-middle justify-around">
            <div className="m-auto pb-1">
                <div className="relative w-[360px] h-[12px]">
                    <div className={"rounded-l-full h-full border-2 absolute bg-black"} style={{ width: `${percentage}%` }}></div>
                    <div className="rounded-full h-full w-full border-2 border-black absolute"></div>
                </div>
            </div>
            
        </div>
    </div>
}
